enum Environments {
  Production = 'production',
  Development = 'development',
  Staging = 'staging'
}

export const ENVIRONMENT = process.env.REACT_APP_ENV as Environments;

export const PLATFORM = {
  production: {
    file_base_url: process.env.REACT_APP_BASE_FILE_URL_PROD!,
    file_content_url: process.env.REACT_APP_FILE_CONTENT_URL_PROD!,
    file_thumbnail_url: process.env.REACT_APP_FILE_THUMBNAIL_URL_PROD!,
    profile_url: process.env.REACT_APP_PROFILE_URL_PROD!,
    tus_endpoint: process.env.REACT_APP_TUS_ENDPOINT_PROD!,
    companion_endpoint: process.env.REACT_APP_COMPANION_ENDPOINT_PROD!
  },
  development: {
    file_base_url: process.env.REACT_APP_BASE_FILE_URL_DEV!,
    file_content_url: process.env.REACT_APP_FILE_CONTENT_URL_DEV!,
    file_thumbnail_url: process.env.REACT_APP_FILE_THUMBNAIL_URL_DEV!,
    profile_url: process.env.REACT_APP_PROFILE_URL_DEV!,
    tus_endpoint: process.env.REACT_APP_TUS_ENDPOINT_DEV!,
    companion_endpoint: process.env.REACT_APP_COMPANION_ENDPOINT_DEV!
  },
  staging: {
    file_base_url: process.env.REACT_APP_BASE_FILE_URL_STAGE!,
    file_content_url: process.env.REACT_APP_FILE_CONTENT_URL_STAGE!,
    file_thumbnail_url: process.env.REACT_APP_FILE_THUMBNAIL_URL_STAGE!,
    profile_url: process.env.REACT_APP_PROFILE_URL_STAGE!,
    tus_endpoint: process.env.REACT_APP_TUS_ENDPOINT_STAGE!,
    companion_endpoint: process.env.REACT_APP_COMPANION_ENDPOINT_STAGE!
  }
};

const AWSConfigByEnv = {
  production: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION_PROD,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION_PROD,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID_PROD,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_PROD
  },
  development: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION_DEV,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION_DEV,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID_DEV,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_DEV
  },
  staging: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION_STAGE,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION_STAGE,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID_STAGE,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_STAGE
  }
};

// eslint-disable-next-line @typescript-eslint/dot-notation
export default AWSConfigByEnv[ENVIRONMENT] ?? AWSConfigByEnv[Environments.Development];
export const PlatformConfigByEnv = PLATFORM[ENVIRONMENT] ?? PLATFORM[Environments.Development];
