import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Logo } from '@shared/components';
import { changeFilesSearchTerm } from '@features/files/filesSlice';
import { FilesViewSwitcher } from '@features/files/FilesViewSwitcher';
import { useLocation } from 'react-router-dom';
import { AccountMenu } from '@common';
import SearchField from '@common2/User/SearchField/SearchField';

interface AccountHeaderProps {
  fileAccessType?: string;
  isAdminOrStaff?: boolean;
}

export const AccountHeader = ({ fileAccessType, isAdminOrStaff = false }: AccountHeaderProps) => {
  const { pathname } = useLocation();
  const isNetworkPage = pathname.includes('network');
  const isTrashPage = pathname.includes('trash');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const searchInput = useAppSelector((state) => state.files.searchTerm);
  const onSearch = (newSearchTerm: string) => {
    dispatch(changeFilesSearchTerm(newSearchTerm));
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: 1 }}>
      {isAdminOrStaff && <Logo sx={{ height: 45 }} />}
      {!isAdminOrStaff && (
        <Stack direction="row" justifyContent="start" spacing={2} sx={{ width: 1 }}>
          <SearchField value={searchInput} onChange={onSearch} placeholder={t('home.searchBox')} />
          <FilesViewSwitcher
            fileAccessType={fileAccessType}
            disableGrid={isNetworkPage || isTrashPage}
          />
        </Stack>
      )}
      <AccountMenu />
    </Stack>
  );
};
