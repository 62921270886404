import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormatListBulletedOutlined, ViewComfy } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PREVIEW } from '@helpers';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory, useLocation } from 'react-router-dom';
import { FilesViewType, GRID, LIST } from './types';
import {
  changeFilesViewType,
  removeFromFileBreadcrumbs,
  selectActiveFileId,
  selectFilesViewType
} from './filesSlice';

const SwitcherButton = styled(ToggleButton)(({ theme }) => ({
  width: 40,
  height: 40,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

interface FilesViewSwitcherProps {
  fileAccessType?: string;
  disableGrid?: boolean;
}

export const FilesViewSwitcher = ({
  fileAccessType,
  disableGrid = false
}: FilesViewSwitcherProps) => {
  const activeViewType = useAppSelector(selectFilesViewType);
  const activeFileId = useAppSelector(selectActiveFileId);
  const history = useHistory();
  const { pathname } = useLocation();
  const isDetailedViewPage = pathname.includes(PREVIEW);
  const disabledDetailedViewSwitcherButtonList = isDetailedViewPage && activeViewType === GRID;
  const disabledDetailedViewSwitcherButtonGrid =
    (isDetailedViewPage && activeViewType === LIST) || disableGrid;

  const dispatch = useAppDispatch();

  const handleViewChange = (_: React.MouseEvent<HTMLElement>, viewType: FilesViewType) => {
    dispatch(changeFilesViewType(viewType || activeViewType));
    dispatch(removeFromFileBreadcrumbs(activeFileId));
    if (fileAccessType) history.push(`/home/files/${fileAccessType}`);
  };

  return (
    <ToggleButtonGroup value={activeViewType} exclusive onChange={handleViewChange}>
      <SwitcherButton
        value={LIST}
        disableTouchRipple
        disabled={disabledDetailedViewSwitcherButtonList}
      >
        <FormatListBulletedOutlined />
      </SwitcherButton>
      <SwitcherButton
        value={GRID}
        disableTouchRipple
        disabled={disabledDetailedViewSwitcherButtonGrid}
      >
        <ViewComfy />
      </SwitcherButton>
    </ToggleButtonGroup>
  );
};
