import { FileWithPath } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import path from 'path';

import {
  FileTreeNode,
  UploadedFile,
  UploadFileCompleteRequestItem,
  UploadFileRequestItem,
  UploadFilesMap,
  UploadPart,
  TempFiles,
  NestedFileNodes
} from '@features/files/types';
import { DocumentContentType, RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE } from '@helpers';
import { Dispatch, SetStateAction } from 'react';
import { replaceNonPrintableCharacters } from '@utils/textHelpers';

const createFile = (
  parentId: string,
  { name, content, type, size, children }: FileTreeNode,
  result: UploadFilesMap
) => {
  const newId = uuidv4();
  // eslint-disable-next-line no-param-reassign
  result[newId] = { id: newId, parentId, name, type, size, content };
  children.forEach((fileNode) => createFile(newId, fileNode, result));
};

const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.');
  if (parts.length > 1) {
    const extension = parts.pop()!.toLowerCase();
    return `.${extension}`;
  }
  return '';
};

export const convertToUploadData = (directoryId: string, files: FileWithPath[]): UploadFilesMap => {
  const fileNodes: FileTreeNode[] = [];
  const temp: TempFiles = {
    fileNodes
  };

  // TODO Improve
  files.forEach((file) => {
    if (file?.path) {
      file?.path
        ?.split(path.sep)
        .filter((p) => p !== '')
        .reduce((prev, name, currentIndex, array) => {
          const isDir = currentIndex !== array.length - 1;

          if (!prev[name]) {
            // eslint-disable-next-line no-param-reassign
            prev[name] = {
              fileNodes: []
            };
            prev.fileNodes.push({
              name: replaceNonPrintableCharacters(name),
              type: isDir ? DocumentContentType.DIRECTORY : (file.type as DocumentContentType),
              size: !isDir ? file.size : 0,
              content: !isDir ? file : undefined,
              children: (prev[name] as NestedFileNodes).fileNodes
            });
          }
          return prev[name] as NestedFileNodes;
        }, temp);
    } else {
      file?.name
        ?.split(path.sep)
        .filter((p) => p !== '')
        .reduce((prev, name, currentIndex, array) => {
          const isDir = currentIndex !== array.length - 1;

          if (!prev[name]) {
            // eslint-disable-next-line no-param-reassign
            prev[name] = {
              fileNodes: []
            };
            prev.fileNodes.push({
              name: replaceNonPrintableCharacters(name),
              type: isDir ? DocumentContentType.DIRECTORY : (file.type as DocumentContentType),
              size: !isDir ? file.size : 0,
              content: !isDir ? file : undefined,
              children: (prev[name] as NestedFileNodes).fileNodes
            });
          }
          return prev[name] as NestedFileNodes;
        }, temp);
    }
  });

  const result: UploadFilesMap = {};
  fileNodes.forEach((fileNode) => createFile(directoryId, fileNode, result));

  return result;
};

export const getUploadFilesRequestItems = (
  filesWithData: UploadFilesMap
): UploadFileRequestItem[] => {
  return Object.values(filesWithData).map(({ id, parentId, name, type, size }) => ({
    file_id: id,
    name,
    description: '',
    content_type: type || name.split('.')[name.split('.').length - 1],
    upload_size: size,
    parent_dir_id: parentId
  }));
};

const getUploadPartsPromise = async ({
  fileData,
  nextPartFromSize,
  url,
  size
}: {
  fileData: Blob;
  nextPartFromSize: number;
  url: string;
  size: number;
}) => {
  // eslint-disable-next-line no-await-in-loop
  return fetch(url, {
    method: 'PUT',
    body: fileData.slice(nextPartFromSize, nextPartFromSize + size)
  });
};

const buildUploadResults = async (
  fileData: Blob,
  uploadParts: UploadPart[],
  setUploadedFilesSize: Dispatch<SetStateAction<number>>
) => {
  const responseBuildUploadResults: Response[] = [];
  let nextPartFromSize = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const { url, size } of uploadParts) {
    // eslint-disable-next-line no-await-in-loop
    let uploadPartsPromise = null;
    let counterRetryUpload = 0;
    while (!uploadPartsPromise && counterRetryUpload < RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE) {
      // eslint-disable-next-line no-await-in-loop
      uploadPartsPromise = await getUploadPartsPromise({
        url,
        size,
        fileData,
        nextPartFromSize
      }).catch(() => {
        return null;
      });
      counterRetryUpload += 1;
    }
    // eslint-disable-next-line no-await-in-loop
    nextPartFromSize += size;
    setUploadedFilesSize((prevSize) => prevSize + size);

    if (uploadPartsPromise) responseBuildUploadResults.push(uploadPartsPromise);
  }

  return responseBuildUploadResults;
};

export const uploadFileWithParts = async (
  { file_id, user_id, upload_id, upload_parts }: UploadedFile,
  filesWithData: UploadFilesMap,
  setUploadedFilesNumber: Dispatch<SetStateAction<number>>,
  setUploadedFilesSize: Dispatch<SetStateAction<number>>
): Promise<UploadFileCompleteRequestItem> => {
  const { name, type, size } = filesWithData[file_id];

  const uploadResults = await buildUploadResults(
    filesWithData[file_id].content!,
    upload_parts,
    setUploadedFilesSize
  );

  setUploadedFilesNumber((amount) => amount + 1);

  return {
    file_id,
    upload_id,
    user_id,
    fileName: name,
    fileType: type,
    filesize: size,
    upload_results: uploadResults.map((ur, idx) => ({
      part_number: upload_parts[idx].number,
      etag: ur.headers.get('ETag') ?? ''
    }))
  } as UploadFileCompleteRequestItem;
};
