import { DocumentContentType, SortDirection } from '@helpers';
import { SxProps } from '@mui/material/styles';

import { ListEntity } from '@shared/components/list-view-v2/types';

export interface Styles {
  [key: string]: SxProps;
}

export type SortValue = SortDirection | false;

export interface Sort {
  key: string;
  direction: SortValue;
}

export type SortFn = (option: Sort) => (a: ListEntity, b: ListEntity) => number;

export interface Page<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
  first: number;
  last: number;
}

export const DragTypes = {
  ELEMENT: 'element',
  DIRECTORY: DocumentContentType.DIRECTORY
} as const;

export enum RequestStatus {
  LOADING = 'loading',
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}
