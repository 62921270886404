import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import App from './pages/App';
import store from './store';
import './i18n';
import awsConfig from './aws-exports';
import sentryConfig from './sentry-exports';

declare global {
  interface Window {
    dataLayer?: Array<any>;
  }
}

Amplify.configure(awsConfig);
Sentry.init(sentryConfig);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
