// @ts-nocheck
import React, { ChangeEvent, ChangeEventHandler, PropsWithChildren } from 'react';
import {
  Box,
  FormControl,
  InputBase,
  InputBaseProps,
  InputLabel,
  MenuItem,
  NativeSelectProps,
  Select,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Progress from '@shared/components/Progress';

type TextControlProps = InputBaseProps & {
  label?: string;
  isLoading?: boolean;
};
export const InputWithAdornment = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 40,
  width: '100%',
  borderRadius: '4px',
  verticalAlign: 'middle',
  boxSizing: 'border-box',
  backgroundColor: '#fafbfc',
  border: '1px solid #dfe3eb',
  'label + &': {
    marginTop: theme.spacing(1)
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: theme.palette.error.main
  },
  '&:focus-visible': {
    outline: 'none'
  }
}));

export const TextInput = styled(InputBase)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(14),
  fontWeight: 400,
  '& .MuiInputBase-input': {
    padding: 0,
    marginLeft: '12px',
    letterSpacing: theme.typography.pxToRem(-0.096),
    '&::placeholder': {
      color: theme.palette.text.primary,
      opacity: '0.5'
    }
  },
  '.Mui-disabled': {
    opacity: '0.5 !important',
    WebkitTextFillColor: '#000000 !important'
  }
}));

export const Label = styled(InputLabel)(({ theme }) => ({
  position: 'relative',
  color: theme.palette.text.primary,
  transform: 'none',
  opacity: 0.6,
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(15),
  '&.Mui-focused': {
    color: theme.palette.text.primary
  }
}));

export const TextFormControl = styled(FormControl)({
  position: 'relative',
  width: '100%'
});

export const SelectFormControl = styled(Select)(() => ({
  '.css-oc4g41-MuiSelect-select-MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#b2b6c0'
  }
}));

export const TextControl = (props: PropsWithChildren<TextControlProps>) => {
  const { id, label, error, children, sx, multiline, isLoading } = props;
  const inputProps = {
    ...props,
    sx: undefined
  };

  return (
    <TextFormControl sx={sx}>
      {label && (
        <Label shrink htmlFor={id}>
          {label}
        </Label>
      )}
      <InputWithAdornment sx={multiline ? { height: 'auto' } : {}}>
        {isLoading && <Progress size="20px" sxRelative={{ height: '100%', overflow: 'auto' }} />}
        <TextInput {...inputProps} />
      </InputWithAdornment>

      {/* Error message */}
      {error && children}
    </TextFormControl>
  );
};

type SelectControlProps = NativeSelectProps & {
  label?: string;
};

const SelectPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  opacity: 0.5
}));

export const SelectControl = (props: PropsWithChildren<SelectControlProps>) => {
  const { id, label, value, children, placeholder, sx, name, onChange, disabled } = props;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <TextFormControl sx={sx}>
      {label && (
        <Label
          shrink
          htmlFor={id}
          sx={{
            position: 'absolute',
            top: -25
          }}
        >
          {label}
        </Label>
      )}
      <SelectFormControl
        sx={{ border: '1px solid #DFE3EB', borderRadius: '5px' }}
        value={value}
        name={name}
        input={<TextInput />}
        displayEmpty
        onChange={(event: any) => handleChange(event)}
        renderValue={(selected) => {
          return String(selected).length === 0 ? (
            <SelectPlaceholder sx={{ padding: '5px', fontSize: '0.875rem' }}>
              {placeholder}
            </SelectPlaceholder>
          ) : (
            <Typography sx={{ padding: '5px 0' }}>{String(selected)}</Typography>
          );
        }}
        disabled={disabled}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {children}
      </SelectFormControl>
    </TextFormControl>
  );
};

export interface CustomSelectOption {
  value: string;
  label: string;
  isNewFolder?: boolean;
}

interface CustomSelectProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  label: string;
  value: string | number;
  placeholder: string;
  onChange: ChangeEventHandler | undefined;
  options: CustomSelectOption[];
}

export const CustomSelect = ({
  id,
  name,
  label,
  value,
  options,
  placeholder,
  onChange,
  disabled
}: CustomSelectProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <TextFormControl>
      {label && (
        <Label
          shrink
          htmlFor={id}
          sx={{
            position: 'absolute',
            top: -25
          }}
        >
          {label}
        </Label>
      )}
      <Select
        sx={{ border: '1px solid #DFE3EB', borderRadius: '5px' }}
        value={value}
        name={name}
        disableUnderline={disabled ?? false}
        disabled={disabled ?? false}
        input={<TextInput />}
        displayEmpty
        onChange={(event: any) => handleChange(event)}
        renderValue={(selected) => {
          return String(selected).length === 0 ? (
            <SelectPlaceholder sx={{ p: '5px', fontSize: '0.875rem' }}>
              {placeholder}
            </SelectPlaceholder>
          ) : (
            <Typography sx={{ py: '5px', px: 0 }}>
              {options.find((option) => option.value === selected)?.label ?? ''}
            </Typography>
          );
        }}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={`so-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </TextFormControl>
  );
};
