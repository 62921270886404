import { getFirstWordUpperCase } from './getFirstWordToUpperCase';

export const getFirstWordUpperCaseBySlashSeparate = (str: string) => {
  if (!str.includes('/')) return str;
  const res: string[] = [];

  str.split('/').forEach((string) => {
    res.push(getFirstWordUpperCase(string));
  });

  return res.join('/');
};
