import { useMemo } from 'react';
import { File } from '@features/files/types';

import { Pagination } from '@state/types';
import { PREVIEW_PAGE_SIZE } from '@helpers';
import { Page } from './types';

export const getBorderElements = ({ totalCount, currentPage, pageSize }: Pagination) => {
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const firstElement = currentPage * pageSize;

  let lastElement = (currentPage + 1) * pageSize - 1;
  if (lastElement >= totalCount) {
    lastElement = totalCount - 1;
  }

  return {
    totalPageCount,
    firstElement,
    lastElement
  };
};

export const usePagination = ({ totalCount, currentPage, pageSize }: Pagination) => {
  return useMemo(() => {
    return getBorderElements({ totalCount, currentPage, pageSize });
  }, [totalCount, currentPage, pageSize]);
};

export const getPage = <T>(data: T[], pageNumber: number, pageSize: number): Page<T> => {
  const totalElements = data.length;
  const totalPages = Math.ceil(totalElements / pageSize);
  const first = (pageNumber - 1) * pageSize;

  let last = pageNumber * pageSize;
  if (last >= totalElements) {
    last = totalElements;
  }

  return {
    content: data.slice(first, last),
    totalElements,
    totalPages,
    size: pageSize,
    first: first + 1,
    last,
    number: pageNumber
  };
};

export const getByPages = <T>(data: T[], pages: number, pageSize: number): T[] => {
  if (!data.length) return [];
  return data.slice(0, pages * pageSize);
};

export const getSliceByPages = (
  data: File[],
  config: { startIndex: number; currentId: string } = {
    startIndex: 0,
    currentId: ''
  }
): {
  content: File[];
  currentIdx: number;
} => {
  if (!data.length) return { content: [], currentIdx: 0 };

  const { startIndex, currentId } = config;

  let firstElementBySlice = startIndex - PREVIEW_PAGE_SIZE;

  if (firstElementBySlice < 0) firstElementBySlice = 0;

  let lastElementBySlice = startIndex + PREVIEW_PAGE_SIZE;

  if (lastElementBySlice >= data.length) lastElementBySlice = data.length;

  const result = data.slice(firstElementBySlice, lastElementBySlice);
  const currentIdxByResult = result.findIndex(({ file_id }) => file_id === currentId);

  return {
    content: result,
    currentIdx: currentIdxByResult
  };
};
