import { DocumentContentType } from '@helpers';

// TODO Change images
export const getMediaTypeImg = (
  content_type: DocumentContentType | undefined,
  thumbnail_url: string | undefined
) => {
  if (thumbnail_url) {
    return thumbnail_url;
  }
  switch (content_type) {
    case DocumentContentType.IMAGE_JPEG:
    case DocumentContentType.IMAGE_PNG:
      return '/img/media-type/image.svg';
    case DocumentContentType.DIRECTORY:
      return '/img/media-type/directory.svg';
    case DocumentContentType.AUDIO_MPEG:
      return '/img/media-type/mp3.svg';
    case DocumentContentType.VIDEO_AVI:
      return '/img/media-type/avi.svg';
    case DocumentContentType.VIDEO_MP4:
      return '/img/media-type/mp4.svg';
    case DocumentContentType.VIDEO_WMV:
      return '/img/media-type/video.svg';
    case DocumentContentType.APPLICATION_PDF:
      return '/img/media-type/pdf.svg';
    case DocumentContentType.TEXT_HTML:
      return '/img/media-type/html.svg';
    case DocumentContentType.APPLICATION_MSWORD:
      return '/img/media-type/doc.svg';
    case DocumentContentType.APPLICATION_EXCEL:
      return '/img/media-type/xls.svg';
    case DocumentContentType.APPLICATION_POWERPOINT:
      return '/img/media-type/ppt.svg';
    case DocumentContentType.TEXT_PLAIN:
    default:
      if (content_type?.includes('text')) {
        return '/img/media-type/text.svg';
      }
      if (content_type?.includes('image')) {
        return '/img/media-type/img.svg';
      }
      if (content_type?.includes('video')) {
        return '/img/media-type/video.svg';
      }
      return '/img/media-type/undefined.svg';
  }
};

export const handleImageRetryOnError = (imgRef: any, url: string, retries = 5) => {
  if (imgRef) {
    // eslint-disable-next-line no-param-reassign
    imgRef.onerror = () => {
      if (retries > 0) {
        setTimeout(() => {
          handleImageRetryOnError(imgRef, url, retries - 1);
        }, 1000);
      } else {
        // eslint-disable-next-line no-param-reassign
        imgRef.src = '/img/media-type/placeholder/image.jpg';
      }
    };
    // eslint-disable-next-line no-param-reassign
    imgRef.src = url;
  }
};
