import { DocumentContentType } from '@helpers';

export const getNewDirectiryObj = (
  file_id: string,
  parent_dir_id: string,
  directoryName: string
) => ({
  file_id,
  parent_dir_id,
  name: directoryName,
  description: '',
  content_type: DocumentContentType.DIRECTORY
});
