import { Reducer } from 'redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import filesReducer from '@features/files/filesSlice';
import trashReducer from '@state/trash/trashSlice';
import authReducer from '@state/authSlice';
import myNetworkReducer from '@state/users/myNetworkSlice';
import usersReducer from '@state/users/userSlice';
import { RESET_STATE_ACTION_TYPE } from '@state/actions';
import { unauthenticatedMiddleware } from '@state/middleware/unauthenticatedMiddleware';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from '@state/apiSlice';
import { upgradePlanReducer } from '@state/UpgradePlan';

const reducers = {
  auth: authReducer,
  files: filesReducer,
  trash: trashReducer,
  users: usersReducer,
  myNetwork: myNetworkReducer,
  upgradePlan: upgradePlanReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    // eslint-disable-next-line no-param-reassign
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat([unauthenticatedMiddleware, apiSlice.middleware])
});

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;

setupListeners(store.dispatch);
