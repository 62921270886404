import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { FetchFilesPayload } from '@state/types';
import { trashFilesApi } from '@state/trash/trashFilesApi';
import { $AuthApi } from '@services';
import { RootState } from '@store';
import { getFilesByURL, updateWithAdditionalInfo } from '@features/files/utils';
import { FetchFilesResponse, File } from '@features/files/types';

export const fetchFileInTrash = createAsyncThunk<FetchFilesPayload>('trash/fetchAll', async () => {
  let files: File[] = [];
  const currentDirectory = {
    id: '.',
    name: 'HOME',
    isFile: false
  };

  const filesResponse = await trashFilesApi.getFilesInTrash();
  const filesResponseData = filesResponse.data as FetchFilesResponse;

  if (filesResponseData.files) {
    files = filesResponseData.files;
  }

  if (filesResponseData.url) {
    files = await getFilesByURL(filesResponseData.url);
  }

  return {
    currentDirectory,
    files: files.map(updateWithAdditionalInfo) || []
  };
});

export const hardDeleteFiles = createAsyncThunk<EntityId[], void>(
  'trash/hardDelete',
  async (_, { getState }) => {
    const operations: any = (getState() as RootState).trash.selectedIds.map((id) => ({
      file_id: id
    }));

    await $AuthApi.post('/files/operations', {
      type: 'HARD_DELETE',
      payload: { files: operations, parameters: {} }
    });
    return operations.map((file: File) => file.file_id);
  }
);

export const restoreFiles = createAsyncThunk<EntityId[], void>(
  'trash/restore',
  async (_, { getState }) => {
    const operations: any = (getState() as RootState).trash.selectedIds.map((id) => ({
      file_id: id
    }));

    await $AuthApi.post('/files/operations', {
      type: 'RESTORE',
      payload: { files: operations, parameters: {} }
    });
    return operations.map((file: any) => file.file_id);
  }
);
