/* eslint-disable react/destructuring-assignment */
import { h, Fragment } from 'preact';
import User from "./User.js";
import Breadcrumbs from "../Breadcrumbs.js";
export default function Header(props) {
  return h(Fragment, null, props.showBreadcrumbs && h(Breadcrumbs, {
    getFolder: props.getFolder,
    breadcrumbs: props.breadcrumbs,
    breadcrumbsIcon: props.pluginIcon && props.pluginIcon(),
    title: props.title
  }), h(User, {
    logout: props.logout,
    username: props.username,
    i18n: props.i18n
  }));
}
