import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Popover,
  PopoverOrigin,
  Radio,
  RadioGroup,
  Stack,
  styled
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { TextControl } from '@shared/components';
import { Label } from '@shared/components/inputs/Inputs';
import { EmailStartAdornment } from '@shared/components/inputs/Adornments';
import { useAppSelector } from '@hooks/useStore';
import Progress from '@shared/components/Progress';
import { useShareFilesMutation } from '@state/apiSlice';
import { setGlobalErrorToastMessage, setGlobalSuccessToastMessage } from '@common';
import { clearSelectedFilesIds, selectSelectedFilesIds } from '../filesSlice';
import { ShareFilesRequestItem } from '../types';
import { useEnableForOwnerEditor } from '../hooks';

const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: theme.typography.pxToRem(14)
  }
}));

const DialogContent = styled(Stack)(({ theme: { spacing } }) => ({
  width: 400,
  padding: spacing(3)
}));

interface FormData {
  targetEmail: string;
  firstname: string;
  lastname: string;
  permission: string;
}

export interface ShareFilesDialogProps {
  anchorEl: Element | null | undefined;
  onClose: () => void;
  submitBtnText?: string;
  anchorOrigin?: PopoverOrigin;
  isLoading?: boolean;

  handleSubmitCallback?: (filesData: {
    shareFilesData: ShareFilesRequestItem;
    shareFiles: ReturnType<typeof useShareFilesMutation>['0'];
  }) => Promise<void>;
}

const ShareFilesDialog = ({
  anchorEl,
  onClose,
  handleSubmitCallback,
  submitBtnText,
  anchorOrigin,
  isLoading: additionalLoading
}: ShareFilesDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    shareFiles,
    { isLoading: isLoadingRequest, isSuccess, requestId, isError, error, reset }
  ] = useShareFilesMutation();
  const [isLoading, setLoading] = useState(additionalLoading ?? false);

  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (requestId && isSuccess && selectedFilesIds.length) {
      setGlobalSuccessToastMessage(
        `(${selectedFilesIds.length}) ${
          selectedFilesIds.length === 1 ? 'File' : 'Files'
        } Successfully Shared`
      );
      dispatch(clearSelectedFilesIds());
      reset();
    }
    if (requestId && isError && error && selectedFilesIds.length) {
      // eslint-disable-next-line no-console
      console.error('error', error);
      setGlobalErrorToastMessage('Something went wrong, try again later.');
      reset();
    }
  }, [isSuccess, requestId, isError, error, selectedFilesIds]);

  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values: formValues,
    isValid,
    resetForm
  } = useFormik({
    validateOnMount: true,
    initialValues: {
      targetEmail: '',
      firstname: '',
      lastname: '',
      permission: 'viewer'
    },
    validationSchema: yup.object({
      targetEmail: yup.string().email(t('email.error.format')).required(t('email.error.required')),
      firstname: yup.string().required(t('signup.form.firstName.error.required')),
      lastname: yup.string().required(t('signup.form.lastName.error.required'))
    }),
    onSubmit: async ({ targetEmail, firstname, lastname, permission }: FormData) => {
      const shareFilesData: ShareFilesRequestItem[] = [
        {
          target_email: targetEmail.toLowerCase(),
          first_name: firstname,
          last_name: lastname,
          shared_files: selectedFilesIds.map((id) => id.toString()),
          permission
        }
      ];

      if (handleSubmitCallback) {
        setLoading(true);
        await handleSubmitCallback({ shareFilesData: shareFilesData[0], shareFiles });
        setLoading(false);
      } else {
        shareFiles(shareFilesData);
      }
      onClose();
      resetForm();
    }
  });

  return (
    <Popover
      open={open}
      onClose={onClose}
      onBackdropClick={(e) => e.stopPropagation()}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin ?? { vertical: 'bottom', horizontal: 'left' }}
    >
      <DialogContent>
        <Stack component="form" onSubmit={handleSubmit} noValidate>
          <TextControl
            id="targetEmail"
            name="targetEmail"
            value={formValues.targetEmail}
            label={t('email.label')}
            onChange={handleChange}
            placeholder={t('email.placeholder')}
            startAdornment={<EmailStartAdornment />}
            error={touched.targetEmail && !!errors.targetEmail}
          >
            <FormHelperText error>{errors.targetEmail}</FormHelperText>
          </TextControl>
          <Stack direction="row" sx={{ mt: 2 }}>
            <TextControl
              id="firstname"
              name="firstname"
              value={formValues.firstname}
              label={t('user.firstName')}
              onChange={handleChange}
              placeholder={t('user.firstName')}
              error={touched.firstname && !!errors.firstname}
              sx={{ mr: 2 }}
            >
              <FormHelperText error>{errors.firstname}</FormHelperText>
            </TextControl>
            <TextControl
              id="lastname"
              name="lastname"
              value={formValues.lastname}
              label={t('user.lastName')}
              onChange={handleChange}
              placeholder={t('user.lastName')}
              error={touched.lastname && !!errors.lastname}
            >
              <FormHelperText error>{errors.lastname}</FormHelperText>
            </TextControl>
          </Stack>
          <FormControl sx={{ mt: 4 }}>
            <Label id="permission">Permission</Label>
            <RadioGroup
              value={formValues.permission}
              onChange={handleChange}
              name="permission"
              row
              aria-labelledby="permission"
            >
              <RadioLabel value="viewer" control={<Radio />} label="Viewer" />
              <RadioLabel value="contributor" control={<Radio />} label="Contributor" />
              <RadioLabel
                value="editor"
                control={<Radio />}
                label="Editor"
                disabled={!useEnableForOwnerEditor()}
              />
            </RadioGroup>
          </FormControl>
          <Button disabled={!isValid} type="submit" variant="contained" sx={{ alignSelf: 'end' }}>
            {t(submitBtnText ?? 'invite')}
          </Button>
        </Stack>
      </DialogContent>
      {isLoading && <Progress position="absolute" />}
    </Popover>
  );
};

export default ShareFilesDialog;
