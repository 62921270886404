import React, { StrictMode } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useAppSelector } from '@hooks/useStore';
import { AuthProvider, PageLoader } from '@shared/components';
import theme from '@shared/theme';
import { GlobalToast } from '@common';
import Routing from './routing/routing';

const App = () => {
  const loading = useAppSelector((state) => state.auth.loading);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <StrictMode>
          <PageLoader open={loading} />
          <GlobalToast />
          {!loading && <Routing />}
        </StrictMode>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
