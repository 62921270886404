import styled from 'styled-components';

export const ModalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background: #fff;
  gap: 20px;
`;

export const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  gap: 12px;
`;

export const ModalContent = styled.div`
  width: 100%;
  white-space: pre-line;
  text-align: center;
`;
