import { useState, useMemo } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { FileWithPath } from 'react-dropzone';

import { ListColumnDef } from '@shared/components/list-view-v2';
import { MediaTypeColumn } from '@features/files/MediaTypeColumn';
import { getNotAllowedStatusUpload } from '@utils/getNotAllowedStatusUpload';
import { filesCompareFn } from '@features/files/utils';
import {
  getTransformedPayloadToExtensivePayload,
  useCompleteFilesUploadMutation,
  useCreateDirectoriesMutation,
  useGetAllFilesQuery,
  useGetFileDetailsQuery,
  useGetOwnedFilesQuery,
  useGetReceivedFilesQuery,
  useGetSharedFilesQuery,
  useModifyFilesMutation,
  useUploadFilesMutation
} from '@state/apiSlice';
import { useAppSelector } from '@hooks/useStore';
import { getFilesTotalSizes } from '@utils/getFileTotalSize';
import { ALL, OWNED, RECEIVED, RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE, SHARED } from '@helpers';
import { AnyType } from 'src/globalTypes';
import { getFirstWordUpperCaseBySlashSeparate } from '@utils/getFirstWordUpperCaseBySlashSeparate';
import { setGlobalErrorToastMessage } from '@common';
import { analytics } from '@analytics';
import {
  selectActiveDirectory,
  selectFilesBreadcrumbs,
  selectSelectedFilesIds
} from './filesSlice';
import { convertToUploadData, getUploadFilesRequestItems } from './upload/upload';
import {
  completeUpload,
  getNumberOfFilesToUpload,
  isDirectory,
  isFileEditable,
  selectDirectoriesFromApiResult,
  uploadFilesParts
} from './utils';
import {
  CONTRIBUTOR,
  CreateDirectoryResponse,
  EDITOR,
  File,
  FileAccessType,
  OWNER,
  UploadFileCompleteRequestItem,
  UploadFileRequestItem
} from './types';

export const useSairedWithOtherUserFilesColumns = (): ListColumnDef[] => {
  const { t } = useTranslation();
  const [modifyFiles] = useModifyFilesMutation();

  return [
    {
      key: 'content_type',
      headerName: t('filesView.list.columns.mediaType'),
      component: MediaTypeColumn,
      sortable: true,
      width: '15%',
      headerCustomStyles: {
        pl: 3
      }
    },
    {
      key: 'owner_name',
      headerName: t('filesView.list.columns.owner'),
      width: '15%'
    },
    {
      key: 'name',
      headerName: t('filesView.list.columns.name'),
      editable: isFileEditable,
      onDataChange: (newName, id) =>
        modifyFiles(
          getTransformedPayloadToExtensivePayload([id.toString()], {
            name: newName?.toString() ?? ''
          })
        ),
      sortable: true,
      width: '30%'
    },
    {
      key: 'description',
      headerName: t('filesView.list.columns.description'),
      editable: isFileEditable,
      onDataChange: (newDescription, id) =>
        modifyFiles(
          getTransformedPayloadToExtensivePayload([id.toString()], {
            description: newDescription?.toString() ?? ''
          })
        ),
      sortable: true,
      width: '30%'
    },
    {
      key: 'access_status',
      headerName: t('filesView.list.columns.sharingStatus'),
      dataFormatter: (value) => {
        if (!value) {
          return value === OWNER ? t('sharingStatus.owned') : t('sharingStatus.sharing');
        }

        const getFirstWordUpperCase = (str: string): string =>
          str ? str[0]?.toUpperCase() + str.slice(1)?.toLowerCase() : '';

        return (value as string).includes('/')
          ? getFirstWordUpperCaseBySlashSeparate(value as string)
          : getFirstWordUpperCase(value as string);
      },
      width: '10%'
    }
  ];
};

export const useFilesListColumns = (): ListColumnDef[] => {
  const { t, i18n } = useTranslation();
  const [modifyFiles] = useModifyFilesMutation();

  return [
    {
      key: 'content_type',
      headerName: t('filesView.list.columns.mediaType'),
      component: MediaTypeColumn,
      sortable: true,
      width: '15%',
      headerCustomStyles: {
        pl: 3
      }
    },
    {
      key: 'name',
      headerName: t('filesView.list.columns.name'),
      editable: isFileEditable,
      onDataChange: (newName, id) =>
        modifyFiles(
          getTransformedPayloadToExtensivePayload([id.toString()], {
            name: newName?.toString() ?? ''
          })
        ),
      sortable: true,
      width: '30%'
    },
    {
      key: 'description',
      headerName: t('filesView.list.columns.description'),
      editable: isFileEditable,
      onDataChange: (newDescription, id) =>
        modifyFiles(
          getTransformedPayloadToExtensivePayload([id.toString()], {
            description: newDescription?.toString() ?? ''
          })
        ),
      sortable: true,
      width: '30%'
    },
    {
      key: 'last_updated',
      headerName: t('filesView.list.columns.modifiedDate'),
      dataFormatter: (value) => {
        return DateTime.fromSQL(value?.toString() || '').toFormat('DD', {
          locale: i18n.language
        });
      },
      width: '15%'
    },
    {
      key: 'access_status',
      headerName: t('filesView.list.columns.sharingStatus'),
      dataFormatter: (value) => {
        if (!value) {
          return value === OWNER ? t('sharingStatus.owned') : t('sharingStatus.sharing');
        }

        return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
      },
      width: '10%'
    }
  ];
};

export const useFilesUpload = () => {
  const [createDirectories] = useCreateDirectoriesMutation();
  const [completeFilesUpload] = useCompleteFilesUploadMutation();
  const [uploadFiles] = useUploadFilesMutation();

  const [isStarted, setIsStarted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [filesNumber, setFilesNumber] = useState(0);
  const [filesSize, setFilesSize] = useState(0);
  const [uploadedFilesNumber, setUploadedFilesNumber] = useState(0);
  const [uploadedFilesSize, setUploadedFilesSize] = useState(0);

  const uploadFilesIntoDirectory = async (
    directoryId: string,
    acceptedFiles: FileWithPath[],
    currentUserId: string,
    callback: () => void
  ) => {
    console.log('directoryId', directoryId);
    console.log('acceptedFiles', acceptedFiles);
    console.log('currentUserId', currentUserId);
    const calculateFilesData = acceptedFiles.reduce(
      // @ts-ignore
      (acc, currentValue) => ({
        number_of_files: acc.number_of_files + 1,
        size: acc.size + currentValue.size,
        files: [...acc.files, { type: currentValue.type, size: currentValue.size }]
      }),
      { number_of_files: 0, size: 0, files: [] }
    );
    // @ts-ignore
    analytics.userUploadFiles({ ...calculateFilesData, status: 'start' });
    const filesWithData = convertToUploadData(directoryId, acceptedFiles);
    console.log('filesWithData', filesWithData);
    const uploadPayloadFiles: UploadFileRequestItem[] = getUploadFilesRequestItems(filesWithData);
    console.log('uploadPayloadFiles', uploadPayloadFiles);

    const directoriesDataFiles = uploadPayloadFiles.filter(({ content_type }) =>
      isDirectory(content_type)
    );
    console.log('directoriesDataFiles', directoriesDataFiles);

    const filesData = {
      user_id: currentUserId,
      files: uploadPayloadFiles.filter(({ content_type }) => !isDirectory(content_type))
    };
    console.log('filesData', filesData);

    setFilesNumber(filesData.files.length);
    setFilesSize(getFilesTotalSizes(filesData.files));

    setIsStarted(true);

    // create all directories
    await directoriesDataFiles.reduce(async (prevPromise: Promise<AnyType>, directory) => {
      await prevPromise;
      return createDirectories({ user_id: currentUserId, files: [directory] }).unwrap();
    }, Promise.resolve());

    let result: CreateDirectoryResponse | null = null;
    let isStopUploading = false;

    // upload files
    while (filesData.files.length > 0) {
      let retryRound = 0;
      const numberOfFilesToUpload = getNumberOfFilesToUpload({
        files: filesData.files,
        filesWithData
      });
      console.log('numberOfFilesToUpload', numberOfFilesToUpload);
      const files = filesData.files.slice(0, numberOfFilesToUpload);

      let completeUploadPayload: UploadFileCompleteRequestItem[] | null = null;

      while (
        retryRound < RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE &&
        !completeUploadPayload &&
        !isStopUploading
      ) {
        // eslint-disable-next-line no-await-in-loop
        completeUploadPayload = await uploadFiles({
          user_id: currentUserId,
          files
        })
          .unwrap()
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .then((filesUploadResponse) =>
            uploadFilesParts(
              filesUploadResponse,
              filesWithData,
              setUploadedFilesNumber,
              setUploadedFilesSize
            )
          )
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .catch(async (err) => {
            console.log('----err', err);
            const isNotAllowedUpload = getNotAllowedStatusUpload(err);

            if (isNotAllowedUpload) {
              const {
                data: { error: errorMessage }
              } = err;
              // @ts-ignore
              analytics.userUploadFiles({
                ...calculateFilesData,
                status: 'failure',
                error: errorMessage
              });

              isStopUploading = true;

              retryRound = 0;
              result = null;
              completeUploadPayload = null;

              setIsSuccess(false);
              setIsStarted(false);
              setUploadedFilesNumber(0);
              setUploadedFilesSize(0);
              setFilesNumber(0);
              setFilesSize(0);

              setGlobalErrorToastMessage(errorMessage);
            }

            return null;
          });
        if (!completeUploadPayload && !isStopUploading) {
          retryRound += 1;
        }
      }

      if (completeUploadPayload && !isStopUploading) {
        // complete upload
        let retryCompleteRound = 0;
        while (retryCompleteRound < RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE && !result) {
          // eslint-disable-next-line no-await-in-loop
          result = (await completeFilesUpload(completeUploadPayload)
            .unwrap()
            .then()
            .catch(async () => {
              return null;
            })) as CreateDirectoryResponse;
          if (!result) {
            retryCompleteRound += 1;
          }
        }
      }
      filesData.files.splice(0, numberOfFilesToUpload);
      if (filesData.files.length) result = null;
    }

    if (result === null && !isStopUploading) {
      let completeUploadPayload: UploadFileCompleteRequestItem[] | null = null;
      let retryRound = 0;
      while (retryRound < RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE && !completeUploadPayload) {
        // eslint-disable-next-line no-await-in-loop
        completeUploadPayload = await uploadFiles(filesData)
          .unwrap()
          .then((filesUploadResponse) =>
            uploadFilesParts(
              filesUploadResponse,
              filesWithData,
              setUploadedFilesNumber,
              setUploadedFilesSize
            )
          )
          .catch(async () => {
            return [];
          });
        if (!completeUploadPayload) {
          retryRound += 1;
        }
      }
      // complete upload
      let retryCompleteRound = 0;
      while (
        retryCompleteRound < RETRY_UPLOAD_FILE_PART_COUNTER_MAX_VALUE &&
        !result &&
        completeUploadPayload
      ) {
        // eslint-disable-next-line no-await-in-loop
        result = (await completeFilesUpload(
          completeUploadPayload
        ).unwrap()) as CreateDirectoryResponse;
        if (!completeUploadPayload) {
          retryCompleteRound += 1;
        }
      }
    }

    if (result && !isStopUploading) {
      // @ts-ignore
      analytics.userUploadFiles({ ...calculateFilesData, status: 'success' });
      completeUpload(result, setIsSuccess, callback);
    }
  };

  return {
    uploadFilesIntoDirectory,
    isStarted,
    setIsStarted,
    isSuccess,
    filesNumber,
    filesSize,
    uploadedFilesNumber,
    setUploadedFilesNumber,
    uploadedFilesSize
  };
};

export const useGetFilesByType = (): File[] => {
  const sort = useAppSelector((state) => state.files.sort);
  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);
  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();
  let data = [];
  switch (fileAccessType) {
    case RECEIVED:
      data = useGetReceivedFilesQuery(activeDirectoryId).data;
      break;
    case OWNED:
      data = useGetOwnedFilesQuery(activeDirectoryId).data;
      break;
    case SHARED:
      data = useGetSharedFilesQuery(activeDirectoryId).data;
      break;
    default:
      data = useGetAllFilesQuery(activeDirectoryId).data;
      break;
  }

  // @ts-ignore
  return useMemo(() => {
    return !data?.length
      ? []
      : data
          ?.filter((file) => file.content_type !== 'directory')
          ?.sort(
            filesCompareFn({
              key: sort.key,
              direction: sort.direction
            })
          );
  }, [data, sort]);
};

export const useGetFiles = (): File[] => {
  // Get all files
  const sort = useAppSelector((state) => state.files.sort);
  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);
  const { data } = useGetAllFilesQuery(activeDirectoryId);

  // @ts-ignore
  return useMemo(() => {
    return !data?.length
      ? []
      : data
          ?.filter((file) => file.content_type !== 'directory')
          ?.sort(
            filesCompareFn({
              key: sort.key,
              direction: sort.direction
            })
          );
  }, [data, sort]);
};

export const useGetFilesDetails = (files: File[]): {}[] => {
  const details = files.map((file) => {
    const { data = {} } = useGetFileDetailsQuery(file.file_id);
    return data;
  });

  // @ts-ignore
  return details.find((file) => !file.file_id) ? [] : details;
};

export const useGetDirectories = (excludeIds?: EntityId[]): File[] => {
  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();
  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);

  const { data: ownedDirectories = [] } = useGetOwnedFilesQuery(activeDirectoryId, {
    selectFromResult: (result) => selectDirectoriesFromApiResult(result, excludeIds),
    skip: ![OWNED, ALL].includes(fileAccessType)
  });

  const { data: receivedDirectories = [] } = useGetReceivedFilesQuery(activeDirectoryId, {
    selectFromResult: (result) => selectDirectoriesFromApiResult(result, excludeIds),
    skip: ![RECEIVED, ALL].includes(fileAccessType)
  });

  const { data: sharedDirectories = [] } = useGetSharedFilesQuery(activeDirectoryId, {
    selectFromResult: (result) => selectDirectoriesFromApiResult(result, excludeIds),
    skip: ![SHARED, ALL].includes(fileAccessType)
  });

  return ownedDirectories.concat(receivedDirectories).concat(sharedDirectories);
};

const getDirectoryName = (directories: string[], name: string, count: number): string => {
  if (directories.includes(name)) {
    return getDirectoryName(directories, `New Album (${count})`, count + 1);
  }

  return name;
};

export const useGetUniqueDirectoryName = (): string => {
  const { t } = useTranslation();
  const defaultDirectoryName = t('directoryName');
  const directories = useGetDirectories();
  const directoriesNames = directories.map((dir) => dir.name);

  if (directoriesNames.includes(defaultDirectoryName)) {
    return getDirectoryName(directoriesNames, defaultDirectoryName, 1);
  }

  return defaultDirectoryName;
};

export const useShareEnabled = () => {
  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const files = useGetFiles();
  const directories = useGetDirectories();

  return files
    ?.concat(directories)
    .filter(({ file_id }) => selectedFilesIds.includes(file_id))
    .every(({ access_status }) => [OWNER, CONTRIBUTOR, EDITOR].includes(access_status));
};

export const useCopyEnabled = () => {
  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const files = useGetFiles();
  const directories = useGetDirectories();
  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();

  if ([RECEIVED].includes(fileAccessType)) {
    return files
      ?.concat(directories)
      .filter(({ file_id }) => selectedFilesIds.includes(file_id))
      .every(
        ({ access_status, content_type }) =>
          content_type !== 'directory' && [OWNER, CONTRIBUTOR, EDITOR].includes(access_status)
      );
  }

  return files
    ?.concat(directories)
    .filter(({ file_id }) => selectedFilesIds.includes(file_id))
    .every(({ access_status }) => [OWNER, CONTRIBUTOR, EDITOR].includes(access_status));
};

export const useEnableForOwnerEditor = () => {
  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const files = useGetFiles();
  const directories = useGetDirectories();

  return files
    ?.concat(directories)
    .filter(({ file_id }) => selectedFilesIds.includes(file_id))
    .every(({ access_status }) => [OWNER, EDITOR].includes(access_status));
};

export const useUploadEnabled = (): {
  all: boolean;
  directionEnabled: boolean;
  filesEnabled: boolean;
} => {
  const files = useGetFiles();
  const directories = useGetDirectories();

  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();
  const breadCrumbs = useAppSelector(selectFilesBreadcrumbs);

  if ([SHARED].includes(fileAccessType)) {
    const isRootDirectory = breadCrumbs.length === 1;
    const filesEnabled = breadCrumbs.length > 1;
    const isNotEmptyRootDirectory = !!files.length || !!directories.length;

    return {
      all: fileAccessType === OWNED,
      directionEnabled: isRootDirectory && isNotEmptyRootDirectory,
      filesEnabled
    };
  }

  return {
    all: fileAccessType === OWNED,
    directionEnabled: fileAccessType === OWNED,
    filesEnabled: fileAccessType === OWNED
  };
};
