import React, { SyntheticEvent, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Stack, styled, Typography } from '@mui/material';
import { v4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import {
  useCreateDirectoryWithoutTagMutation,
  useMoveFilesMutation,
  useMoveFilesWithoutTagMutation,
  useShareFilesMutation
} from '@state/apiSlice';
import { CustomSelect } from '@shared/components/inputs/Inputs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CreateDirectoryResponse,
  FileAccessType,
  ROOT_DIRECTORY,
  ShareFilesRequestItem
} from '@features/files/types';
import { getNewDirectiryObj } from '@utils/getNewDirectoryObj';
import { AuthContext } from '@contexts';
import Progress from '@shared/components/Progress';
import { PAGES } from '@helpers';
import { Nullable } from 'src/globalTypes';
import { analytics } from '@analytics';
import { ShareFilesDialogProps } from './ShareFilesDialog';
import { CopyFilesDialogProps } from './CopyFilesDialog';
import { getDirectoriesOptions, getDirectoriesWithStatusEditor } from '../utils';
import {
  clearSelectedFilesIds,
  removeFromFileBreadcrumbs,
  selectActiveDirectory,
  selectActiveFileId,
  selectFilesBreadcrumbs,
  selectSelectedFilesIds
} from '../filesSlice';
import { useGetDirectories } from '../hooks';

const DialogContent = styled(Stack)(({ theme: { spacing } }) => ({
  position: 'relative',
  width: 300,
  height: '100%',
  padding: spacing(6, 3, 3, 3),
  border: '0.5px solid #80aefc'
}));

const ValidationRuleMessage = styled(Typography)(({ theme: { spacing, typography } }) => ({
  marginBottom: spacing(3),
  fontSize: typography.pxToRem(12),
  opacity: 0.5
}));

interface MoveFilesDialogProps {
  anchorEl: Element | null | undefined;
  AdditionalAnchor?: Nullable<
    (props: CopyFilesDialogProps | MoveFilesDialogProps | ShareFilesDialogProps) => JSX.Element
  >;
  onClose: () => void;
}

const MoveFilesDialog = ({ anchorEl, onClose, AdditionalAnchor }: MoveFilesDialogProps) => {
  const [editionalAnchor, setAdditionalAnchor] = useState<Element | null>(null);
  const submitBtnRef = useRef<Nullable<HTMLButtonElement>>(null);

  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const activeFileId = useAppSelector(selectActiveFileId);
  const [moveFiles] = useMoveFilesMutation();
  const [moveFilesWithoutTag] = useMoveFilesWithoutTagMutation();
  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();
  const [isNewFolder, setIsNewFolder] = useState(false);
  const [createDirectories] = useCreateDirectoryWithoutTagMutation();
  const { user } = useContext(AuthContext);

  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const open = Boolean(anchorEl);
  const directories = useGetDirectories(selectedFilesIds);
  const [directoryId, setDirectoryId] = useState('');
  const breadcrumbs = useAppSelector(selectFilesBreadcrumbs);
  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);

  const newFolderLabel = t('folder.new-folder');
  // TODO: Customer cant move files on the all memorise page
  const isSharedFilesPage = pathname.includes(PAGES.MemoriesSharing);
  const isReceivedPage = pathname.includes(PAGES.Received);
  const isMyPrivateMemoriesPage = pathname.includes(PAGES.My);

  const sharedPageLogic = isSharedFilesPage && breadcrumbs.length === 1;
  const handleCloseAdditionalAnchor = () => {
    onClose();
    setDirectoryId('');
    setAdditionalAnchor(null);
  };

  const directoriesOptions = useMemo(() => getDirectoriesOptions(directories), [
    directories,
    activeDirectoryId
  ]);

  const directoriesOptionsWithDefaulOption = useMemo(() => {
    const copyDirectories = isReceivedPage
      ? [...getDirectoriesOptions(getDirectoriesWithStatusEditor(directories))]
      : [...directoriesOptions];

    if (ROOT_DIRECTORY !== activeDirectoryId) {
      copyDirectories.unshift({ value: ROOT_DIRECTORY, label: 'Home' });
    }

    if (isMyPrivateMemoriesPage || sharedPageLogic) {
      copyDirectories.unshift({
        value: v4(),
        label: `+ ${t('Add')} ${newFolderLabel}`,
        isNewFolder: true
      });
    }

    return copyDirectories;
  }, [selectedFilesIds]);

  const handleChangeDirectory = (event: SyntheticEvent) => {
    setDirectoryId((event.target as HTMLSelectElement).value);

    if (AdditionalAnchor && breadcrumbs.length === 1 && isSharedFilesPage) {
      setAdditionalAnchor(submitBtnRef.current);
    }

    if (
      directoriesOptionsWithDefaulOption.find(
        (option) => option.value === (event.target as HTMLSelectElement).value
      )?.isNewFolder
    ) {
      setIsNewFolder(true);
    } else {
      setIsNewFolder(false);
    }
  };

  const shareFilesAndMove = async (
    filesData: {
      shareFilesData: ShareFilesRequestItem;
      shareFiles: ReturnType<typeof useShareFilesMutation>['0'];
    },
    moveFilesData: { ids: string[]; destinationId: string }
  ) => {
    const { shareFiles, shareFilesData } = filesData;

    const updatedShareFilesData: ShareFilesRequestItem[] = [
      {
        ...shareFilesData,
        shared_files: [moveFilesData.destinationId]
      }
    ];

    await moveFilesWithoutTag(moveFilesData);

    await shareFiles(updatedShareFilesData);
  };

  const handleMoveFiles = async (filesData?: {
    shareFilesData: ShareFilesRequestItem;
    shareFiles: ReturnType<typeof useShareFilesMutation>['0'];
  }) => {
    setLoading(true);
    const ids = selectedFilesIds.map((id) => id.toString());

    if (isNewFolder) {
      analytics.userCreateAlbum({
        mode: 'handle move files',
        file_id: directoryId
      });
      const payload = await createDirectories({
        user_id: user?.getUsername() || '',
        files: [getNewDirectiryObj(directoryId, activeDirectoryId, newFolderLabel)]
      }).unwrap();

      if (!payload) return;

      const destinationId = (payload as CreateDirectoryResponse).payload[0].file_id;
      const moveFilesData = {
        ids,
        destinationId
      };

      if (isSharedFilesPage && editionalAnchor && filesData) {
        await shareFilesAndMove(filesData, moveFilesData);
        return;
      }

      await moveFiles(moveFilesData);
      onClose();
    } else {
      const moveFilesData = {
        ids,
        destinationId: directoryId
      };

      if (isSharedFilesPage && editionalAnchor && filesData) {
        await shareFilesAndMove(filesData, moveFilesData);
        return;
      }

      await moveFiles({
        ids,
        destinationId: directoryId
      });
      onClose();
    }
    setLoading(false);

    if (window.location.pathname.includes('/preview')) {
      history.push(`/home/files/${fileAccessType}`);
      dispatch(removeFromFileBreadcrumbs(activeFileId));
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
        setDirectoryId('');
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <DialogContent>
        <CustomSelect
          options={directoriesOptionsWithDefaulOption}
          value={directoryId}
          disabled={!directoriesOptionsWithDefaulOption.length}
          onChange={handleChangeDirectory}
          placeholder={t('bulkAction.move.placeholder')}
          label={t('bulkAction.move.label')}
        />
        <ValidationRuleMessage>{t('bulkAction.location')}</ValidationRuleMessage>
        <Button
          ref={submitBtnRef}
          onClick={() => {
            handleMoveFiles();
            dispatch(clearSelectedFilesIds());
          }}
          disabled={!selectedFilesIds.length || !directoryId}
          variant="contained"
          fullWidth
        >
          {t('bulkAction.move.button')}
        </Button>
      </DialogContent>
      {AdditionalAnchor && (
        <AdditionalAnchor
          isLoading={isLoading}
          handleSubmitCallback={handleMoveFiles}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          submitBtnText="Invite and move"
          anchorEl={editionalAnchor}
          onClose={handleCloseAdditionalAnchor}
        />
      )}
      {isLoading && <Progress position="absolute" />}
    </Popover>
  );
};

export default MoveFilesDialog;
