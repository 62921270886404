/* eslint-disable react/prop-types */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import ContainedBtn from '@shared/components/buttons/MainContainedBtn';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Transition } from '../Transition/TransitionModal';
import { ModalBox, ModalContent, ModalTitle } from './styles';

export interface InformationModalProps {
  isOpen: boolean;
  title: string;
  titleIcon?: string;
  confirmText?: string;
  content: string;
  handleClose: () => void;
}

export const InformationModal = ({
  isOpen,
  handleClose,
  titleIcon,
  title,
  content,
  confirmText
}: InformationModalProps) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: '8px' }
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#000000'
        }}
      >
        <CloseIcon />
      </IconButton>
      <ModalBox>
        <ModalTitle>
          <img
            src={titleIcon || '/img/heirloom_main_logo_icon.svg'}
            alt="icon"
            width={44}
            height={44}
          />
          {title}
        </ModalTitle>
        <ModalContent>{content}</ModalContent>
        <div style={{ width: '100%' }}>
          <ContainedBtn
            onClick={handleClose}
            style={{
              width: '100%',
              height: '48px'
            }}
          >
            {confirmText}
          </ContainedBtn>
        </div>
      </ModalBox>
    </Dialog>
  );
};
