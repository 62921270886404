// @ts-nocheck

import React, { PropsWithChildren } from 'react';
import { ButtonBaseProps } from '@mui/material';
import equal from 'react-fast-compare';
import { MainContainedBtn } from './styles';

export const ContainedBtn = (props: PropsWithChildren<ButtonBaseProps>) => {
  return <MainContainedBtn {...props} variant="contained" />;
};

export default React.memo(ContainedBtn, equal);
