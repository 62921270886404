import { createElement as h, Component } from 'react';
import PropTypes from 'prop-types';
import DashboardPlugin from '@uppy/dashboard';
import { locale, uppy as uppyPropType, plugins, metaFields, cssSize } from "./propTypes.js";
import getHTMLProps from "./getHTMLProps.js";
import nonHtmlPropsHaveChanged from "./nonHtmlPropsHaveChanged.js";
/**
 * React Component that renders a Dashboard for an Uppy instance. This component
 * renders the Dashboard inline, so you can put it anywhere you want.
 */

class Dashboard extends Component {
  componentDidMount() {
    this.installPlugin();
  }
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.uppy !== this.props.uppy) {
      this.uninstallPlugin(prevProps);
      this.installPlugin();
    } else if (nonHtmlPropsHaveChanged(this.props, prevProps)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        uppy,
        ...options
      } = {
        ...this.props,
        target: this.container
      };
      this.plugin.setOptions(options);
    }
  }
  componentWillUnmount() {
    this.uninstallPlugin();
  }
  installPlugin() {
    const {
      uppy,
      ...options
    } = {
      id: 'react:Dashboard',
      inline: true,
      ...this.props,
      target: this.container
    };
    uppy.use(DashboardPlugin, options);
    this.plugin = uppy.getPlugin(options.id);
  }
  uninstallPlugin(props) {
    if (props === void 0) {
      props = this.props;
    }
    const {
      uppy
    } = props;
    uppy.removePlugin(this.plugin);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return h('div', {
      className: 'uppy-Container',
      ref: container => {
        this.container = container;
      },
      ...getHTMLProps(this.props)
    });
  }
}
Dashboard.propsTypes = {
  uppy: uppyPropType,
  disableInformer: PropTypes.bool,
  disableStatusBar: PropTypes.bool,
  disableThumbnailGenerator: PropTypes.bool,
  height: cssSize,
  hideProgressAfterFinish: PropTypes.bool,
  hideUploadButton: PropTypes.bool,
  locale,
  metaFields,
  note: PropTypes.string,
  plugins,
  proudlyDisplayPoweredByUppy: PropTypes.bool,
  showProgressDetails: PropTypes.bool,
  width: cssSize,
  // pass-through to ThumbnailGenerator
  thumbnailType: PropTypes.string,
  thumbnailWidth: PropTypes.number
};
export default Dashboard;
