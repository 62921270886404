import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled, Typography } from '@mui/material';

import { File } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { getMediaTypeImg } from '@utils/imageHelper';
import { Picture } from '@common';
import { Nullable } from 'src/globalTypes';
import { formatDuration } from '@common2/Thumbnails/Video/helpers';

const Image = styled('img')(({ theme }) => ({
  width: 32,
  height: 28,
  marginRight: theme.spacing(2),
  objectFit: 'cover',
  '&.thumbnail': {
    width: 60,
    height: 46,
    borderRadius: '3px'
  }
}));

const Column = styled(Stack)(({ theme }) => ({
  minWidth: 0,
  '&:not(.has-thumbnail)': {
    paddingLeft: theme.spacing(2)
  }
}));

const VideoDuration = styled('div')({
  position: 'absolute',
  left: '50%',
  bottom: '1px',
  color: 'white',
  fontSize: '14px',
  fontWeight: 700,
  borderRadius: '10px',
  backgroundColor: 'rgba(0,0,0,0.5)',
  transform: 'translateX(-50%)',
  padding: '0px 5px'
});

const ColumnContent = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const MediaTypeColumn = (data: ListEntity) => {
  const { t } = useTranslation();
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const imgRef = useRef<Nullable<HTMLImageElement>>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { content_type, thumbnail_url } = data as File;

  useEffect(() => {
    // @ts-ignore
    if (data?.file_metadata?.format?.duration && !!thumbnail_url) {
      // @ts-ignore
      setVideoDuration(Math.round(data?.file_metadata?.format?.duration));
    }
  }, [data]);

  const handleImageError = (event: SyntheticEvent) => {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLImageElement).src = '/img/media-type/placeholder/image.jpg';
  };

  const hasThumbnail = () => {
    return !!thumbnail_url;
  };

  return (
    <Column direction="row" alignItems="center" className={hasThumbnail() ? 'has-thumbnail' : ''}>
      <Box position="relative">
        <Picture
          isAuth
          alt="Media type"
          onError={handleImageError}
          ref={imgRef}
          src={getMediaTypeImg(content_type, thumbnail_url)}
          className={hasThumbnail() ? 'thumbnail' : ''}
          PictureComponent={Image}
        />
        {videoDuration && <VideoDuration>{formatDuration(videoDuration)}</VideoDuration>}
      </Box>
      <ColumnContent>{t([`mediaTypes.${content_type}`, content_type.toUpperCase()])}</ColumnContent>
    </Column>
  );
};
