import React, { SyntheticEvent, useContext, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Stack, styled, Typography } from '@mui/material';

import { useAppSelector } from '@hooks/useStore';
import {
  useCopyFilesMutation,
  useCreateDirectoryWithoutTagMutation,
  useGetFilesCopyLinkMutation,
  useShareFilesMutation
} from '@state/apiSlice';
import { CustomSelect } from '@shared/components/inputs/Inputs';
import {
  CopyUrlResponse,
  CreateDirectoryResponse,
  FileAccessStatus,
  ROOT_DIRECTORY,
  ShareFilesRequestItem,
  VIEWER
} from '@features/files/types';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { AuthContext } from '@contexts';
import { getNewDirectiryObj } from '@utils/getNewDirectoryObj';
import Progress from '@shared/components/Progress';
import { PAGES } from '@helpers';
import { useLocation } from 'react-router-dom';
import { poll } from '@utils/async';
import { Nullable } from 'src/globalTypes';
import { analytics } from '@analytics';
import { getDirectoriesOptions } from '../utils';
import {
  clearSelectedFilesIds,
  selectActiveDirectory,
  selectFilesBreadcrumbs,
  selectSelectedFilesIds
} from '../filesSlice';
import { useGetDirectories } from '../hooks';
import { ShareFilesDialogProps } from './ShareFilesDialog';

const DialogContent = styled(Stack)(({ theme: { spacing } }) => ({
  position: 'relative',
  width: 300,
  height: '100%',
  padding: spacing(6, 3, 3, 3),
  border: '0.5px solid #80aefc'
}));

const ValidationRuleMessage = styled(Typography)(({ theme: { spacing, typography } }) => ({
  marginBottom: spacing(3),
  fontSize: typography.pxToRem(12),
  opacity: 0.5
}));

export interface CopyFilesDialogProps {
  anchorEl: Element | null | undefined;
  AdditionalAnchor?: Nullable<(props: CopyFilesDialogProps | ShareFilesDialogProps) => JSX.Element>;
  onClose: () => void;
  onRefresh?: () => void;
}

const CopyFilesDialog = ({
  anchorEl,
  onClose,
  onRefresh,
  AdditionalAnchor
}: CopyFilesDialogProps) => {
  const [editionalAnchor, setAdditionalAnchor] = useState<Nullable<Element>>(null);
  const submitBtnRef = useRef<Nullable<HTMLButtonElement>>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [copyFiles] = useCopyFilesMutation();
  const [isNewFolder, setIsNewFolder] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [getFilesCopyLink] = useGetFilesCopyLinkMutation();

  const [createDirectories] = useCreateDirectoryWithoutTagMutation();

  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);
  const { user } = useContext(AuthContext);

  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const open = Boolean(anchorEl);
  const directories = useGetDirectories(selectedFilesIds);
  const [directoryId, setDirectoryId] = useState('');
  const isMyPrivateMemoriesPage = pathname.includes(PAGES.My);
  const isSharedFilesPage = pathname.includes(PAGES.MemoriesSharing);

  const breadcrumbs = useAppSelector(selectFilesBreadcrumbs);

  const newFolderLabel = t('folder.new-folder');
  const sharedPageLogic = isSharedFilesPage && ROOT_DIRECTORY === activeDirectoryId;

  const copyDisabled = useMemo(() => {
    const directory = directories.find((dir) => dir.file_id === directoryId);
    return (
      !selectedFilesIds.length ||
      !directoryId ||
      (directory?.access_status as FileAccessStatus) === VIEWER
    );
  }, [selectedFilesIds, directoryId]);

  const directoriesOptions = useMemo(() => getDirectoriesOptions(directories), [directories]);

  const handleCloseAdditionalAnchor = () => {
    onClose();
    setDirectoryId('');
    setAdditionalAnchor(null);
  };

  const directoriesOptionsWithDefaulOption = useMemo(() => {
    const copyDirectories = [...directoriesOptions];

    if (breadcrumbs.length > 1) {
      const label = breadcrumbs?.[breadcrumbs.length - 1]?.name ?? newFolderLabel;
      copyDirectories.unshift({
        value: activeDirectoryId,
        label
      });
    }

    if (isMyPrivateMemoriesPage) {
      copyDirectories.unshift({ value: ROOT_DIRECTORY, label: 'Home' });
    }

    if (isMyPrivateMemoriesPage || sharedPageLogic) {
      copyDirectories.unshift({
        value: v4(),
        label: `+ ${t('Add')} ${newFolderLabel}`,
        isNewFolder: true
      });
    }

    return copyDirectories;
  }, []);

  const handleChangeDirectory = (event: SyntheticEvent) => {
    if (AdditionalAnchor && breadcrumbs.length === 1 && isSharedFilesPage) {
      setAdditionalAnchor(submitBtnRef.current);
    }

    setDirectoryId((event.target as HTMLSelectElement).value);
    if (
      directoriesOptionsWithDefaulOption.find(
        (option) => option.value === (event.target as HTMLSelectElement).value
      )?.isNewFolder
    ) {
      setIsNewFolder(true);
    } else {
      setIsNewFolder(false);
    }
  };

  const shareFilesAndCopy = async (
    filesData: {
      shareFilesData: ShareFilesRequestItem;
      shareFiles: ReturnType<typeof useShareFilesMutation>['0'];
    },
    copyFilesData: { ids: string[]; destinationId: string }
  ) => {
    const { shareFiles, shareFilesData } = filesData;

    const updatedShareFilesData: ShareFilesRequestItem[] = [
      {
        ...shareFilesData,
        shared_files: [copyFilesData.destinationId]
      }
    ];

    const response = await copyFiles(copyFilesData).unwrap();
    const operationId = response?.payload[0]?.result;

    if (operationId) {
      await poll<CopyUrlResponse>(
        () => getFilesCopyLink(operationId).unwrap(),
        (res: CopyUrlResponse) => res.target_quantity === res.completed,
        2000
      );
    }

    await shareFiles(updatedShareFilesData);
  };

  const handleCopyFiles = async (filesData?: {
    shareFilesData: ShareFilesRequestItem;
    shareFiles: ReturnType<typeof useShareFilesMutation>['0'];
  }) => {
    const ids = selectedFilesIds.map((id) => id.toString());
    setLoading(true);
    if (isNewFolder) {
      analytics.userCreateAlbum({
        mode: 'copy files dialog',
        file_id: directoryId
      });
      const payload = await createDirectories({
        user_id: user?.getUsername() || '',
        files: [getNewDirectiryObj(directoryId, activeDirectoryId, newFolderLabel)]
      }).unwrap();

      if (!payload) return;

      const destinationId = (payload as CreateDirectoryResponse).payload[0].file_id;
      const copyFilesData = {
        ids,
        destinationId
      };

      if (isSharedFilesPage && editionalAnchor && filesData) {
        await shareFilesAndCopy(filesData, copyFilesData);
        dispatch(clearSelectedFilesIds());
        return;
      }

      const response = await copyFiles(copyFilesData).unwrap();
      const operationId = response?.payload[0]?.result;

      if (operationId) {
        await poll<CopyUrlResponse>(
          () => getFilesCopyLink(operationId).unwrap(),
          (res: CopyUrlResponse) => res.target_quantity === res.completed,
          2000
        );
      }
    } else {
      const copyFilesData = {
        ids,
        destinationId: directoryId
      };

      if (isSharedFilesPage && editionalAnchor && filesData) {
        await shareFilesAndCopy(filesData, copyFilesData);
        return;
      }

      const response = await copyFiles({
        ids,
        destinationId: directoryId
      }).unwrap();

      const operationId = response?.payload[0]?.result;

      if (operationId) {
        await poll<CopyUrlResponse>(
          () => getFilesCopyLink(operationId).unwrap(),
          (res: CopyUrlResponse) => res.target_quantity === res.completed,
          2000
        );
      }
    }

    dispatch(clearSelectedFilesIds());
    onRefresh?.();
    setLoading(false);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
        setDirectoryId('');
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <DialogContent>
        <CustomSelect
          options={directoriesOptionsWithDefaulOption}
          value={directoryId}
          onChange={handleChangeDirectory}
          placeholder={t('bulkAction.copy.placeholder')}
          label={t('bulkAction.copy.label')}
        />
        <ValidationRuleMessage>{t('bulkAction.location')}</ValidationRuleMessage>
        <Button
          ref={submitBtnRef}
          onClick={() => handleCopyFiles()}
          disabled={copyDisabled}
          variant="contained"
          fullWidth
        >
          {t('bulkAction.copy.button')}
        </Button>
      </DialogContent>
      {AdditionalAnchor && (
        <AdditionalAnchor
          isLoading={isLoading}
          handleSubmitCallback={handleCopyFiles}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          submitBtnText="Invite and copy"
          anchorEl={editionalAnchor}
          onClose={handleCloseAdditionalAnchor}
        />
      )}
      {isLoading && <Progress position="absolute" />}
    </Popover>
  );
};

export default CopyFilesDialog;
