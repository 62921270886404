export function getShortValue(value: string, start: number = 4, end: number = 4): string {
  if (value) {
    const stringValue = value.toString();

    if (stringValue.length > start + end && start !== 0 && end !== 0) {
      return `${stringValue.substr(0, start)}...${stringValue.substr(
        stringValue.length - end,
        end
      )}`;
    }
    return stringValue;
  }

  return value;
}

export function replaceNonPrintableCharacters(input: string): string {
  // Define a regular expression to match non-printable characters
  const nonPrintableRegex = /[\x00-\x1F\x20\x7F-\x9F\u202F]/g;
  // Replace non-printable characters with spaces
  return input.replace(nonPrintableRegex, ' ');
}
