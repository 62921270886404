import React, { SyntheticEvent, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SxProps } from '@mui/material/styles';
import { Button, ButtonGroup, styled } from '@mui/material';
import { InformationModal } from '@common2/Modals/InformationModal/InformationModal';
import {
  FileDownloadOutlined,
  DeleteForeverOutlined,
  ContentCopy,
  Shortcut,
  ShareOutlined
} from '@mui/icons-material';

import useGetCustomerInfo from '@hooks/useGetCustomerInfo';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import {
  useDeleteFilesMutation,
  useGetFilesDownloadIdMutation,
  useGetFilesDownloadLinkMutation
} from '@state/apiSlice';
import { poll } from '@utils/async';
import { ActionsFile, PAGES, SomethingWentWrongGlobalMessage } from '@helpers';
import { ConfirmationModal, setGlobalErrorToastMessage } from '@common';
import { getWordWithLowerCase } from '@utils/getWordWithLowerCase';
import { DownloadUrlResponse, FileAccessType, ROOT_DIRECTORY } from '@features/files/types';
import CopyFilesDialog from '@features/files/actions/CopyFilesDialog';
import MoveFilesDialog from '@features/files/actions/MoveFilesDialog';
import ShareFilesDialog from '@features/files/actions/ShareFilesDialog';
import { useShareEnabled, useEnableForOwnerEditor, useCopyEnabled } from '@features/files/hooks';

import {
  changeSelectedFiles,
  clearSelectedFilesIds,
  removeFromFileBreadcrumbs,
  selectActiveFileId,
  selectFilesBreadcrumbs,
  selectSelectedFilesIds
} from '@features/files/filesSlice';
import { FileService } from '@services';
import {
  checkErrorBodyByDownload,
  checkIsStringErrorMessageBodyByDownload
} from '@utils/checkErrorBodyByDownload';

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(1)
  }
}));

interface BulkFilesActionsProps {
  disabled?: boolean | undefined;
  sx?: SxProps;
  activeActions: ActionsFile[];
  onRefresh?: () => void;
  isDirectorySelected?: boolean;
}

export const BulkFilesActions = ({
  disabled,
  sx,
  activeActions = [],
  onRefresh,
  isDirectorySelected
}: BulkFilesActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const breadcrumbs = useAppSelector(selectFilesBreadcrumbs);
  const activeFileId = useAppSelector(selectActiveFileId);
  const [deleteFiles] = useDeleteFilesMutation();
  const [getFilesDownloadId] = useGetFilesDownloadIdMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getFilesDownloadLink] = useGetFilesDownloadLinkMutation();

  const { fileAccessType } = useParams<{ fileAccessType: FileAccessType }>();
  const selectedFilesIds = useAppSelector(selectSelectedFilesIds);
  const allActionsDisabled = disabled === undefined ? !selectedFilesIds.length : disabled;

  const shareDisabled = !useShareEnabled();
  const editActionsDisabled = !useEnableForOwnerEditor();
  const deleteDisabled = !useEnableForOwnerEditor();
  const copyDisabled = !useCopyEnabled();
  const { isSuspendedStatus } = useGetCustomerInfo();

  const [isShowConfirmationModal, setShowConfirmationModal] = useState(false);
  const [copyAnchorEl, setCopyAnchorEl] = useState<Element | null>();
  const [moveAnchorEl, setMoveAnchorEl] = useState<Element | null>();
  const [shareAnchorEl, setShareAnchorEl] = useState<Element | null>();
  const [isOpenInformationModal, setIsOpenInformationModal] = useState(false);

  const isReceivedPage = pathname.includes(PAGES.Received);
  const isDirectoryOpened = breadcrumbs.some(({ id, isFile }) => id !== ROOT_DIRECTORY && !isFile);
  const receivedPageLogic = isReceivedPage && (isDirectoryOpened || isDirectorySelected);

  const isShairedPage = pathname.includes(PAGES.MemoriesSharing);
  const shairedPageLogic = isShairedPage && isDirectorySelected;

  const handleOpenCopyDialog = (event: SyntheticEvent) => setCopyAnchorEl(event.currentTarget);
  const handleCloseCopyDialog = () => setCopyAnchorEl(null);
  const handleOpenMoveDialog = (event: SyntheticEvent) => setMoveAnchorEl(event.currentTarget);
  const handleCloseMoveDialog = () => setMoveAnchorEl(null);
  const handleOpenShareDialog = (event: SyntheticEvent) => setShareAnchorEl(event.currentTarget);
  const handleCloseShareDialog = () => setShareAnchorEl(null);

  const handleFilesDelete = async () => {
    await deleteFiles(selectedFilesIds.map((id) => id.toString()));
    dispatch(changeSelectedFiles({ ids: selectedFilesIds, selected: false }));
    if (window.location.pathname.includes('/preview')) {
      history.push(`/home/files/${fileAccessType}`);
      dispatch(removeFromFileBreadcrumbs(activeFileId));
    }
  };

  const handleDownload = async () => {
    setIsOpenInformationModal(true);

    try {
      const downloadId = await getFilesDownloadId(
        selectedFilesIds.map((id) => id.toString())
      ).unwrap();

      if (!downloadId) {
        setGlobalErrorToastMessage(`${SomethingWentWrongGlobalMessage} with downloadId`);
        return;
      }

      const { url } = await poll<DownloadUrlResponse>(
        () => getFilesDownloadLink(downloadId).unwrap(),
        (response: DownloadUrlResponse) => response.status === 'Ready' && !!response.url,
        2000
      );

      if (!url) {
        setGlobalErrorToastMessage(`${SomethingWentWrongGlobalMessage} with url`);
        return;
      }
      // good
      FileService.downloadFilesByUrl(url);
      dispatch(clearSelectedFilesIds());
      onRefresh?.();
    } catch (error) {
      let errorMessage: string = SomethingWentWrongGlobalMessage;
      const isNotAllowedDownloadBody = checkErrorBodyByDownload(error);
      if (isNotAllowedDownloadBody) {
        const {
          data: { error: errorBodyMessageByDownload }
        } = error;
        const isStringErrorBodyMessage = checkIsStringErrorMessageBodyByDownload(
          errorBodyMessageByDownload
        );
        if (isStringErrorBodyMessage) {
          errorMessage = errorBodyMessageByDownload;
        }
      }
      setGlobalErrorToastMessage(errorMessage);
    }
  };

  const actionList = useMemo(() => activeActions, [activeActions]);

  const actionsData = useMemo(
    () => ({
      [ActionsFile.DOWNLOAD]: {
        event: handleDownload,
        iconTemplate: <FileDownloadOutlined />,
        dialogTemplate: <></>,
        disabled: isSuspendedStatus
      },
      [ActionsFile.COPY]: {
        event: handleOpenCopyDialog,
        iconTemplate: <ContentCopy />,
        dialogTemplate: (
          <CopyFilesDialog
            AdditionalAnchor={ShareFilesDialog}
            anchorEl={copyAnchorEl}
            onRefresh={onRefresh}
            onClose={handleCloseCopyDialog}
          />
        ),
        disabled: isSuspendedStatus || copyDisabled || shairedPageLogic
      },
      [ActionsFile.MOVE]: {
        event: handleOpenMoveDialog,
        iconTemplate: <Shortcut />,
        dialogTemplate: (
          <MoveFilesDialog
            AdditionalAnchor={ShareFilesDialog}
            anchorEl={moveAnchorEl}
            onClose={handleCloseMoveDialog}
          />
        ),
        disabled: isSuspendedStatus || receivedPageLogic || editActionsDisabled || shairedPageLogic
      },
      [ActionsFile.SHARE]: {
        event: handleOpenShareDialog,
        iconTemplate: <ShareOutlined />,
        dialogTemplate: (
          <ShareFilesDialog anchorEl={shareAnchorEl} onClose={handleCloseShareDialog} />
        ),
        disabled: isSuspendedStatus || shareDisabled
      },
      [ActionsFile.DELETE]: {
        event: () => setShowConfirmationModal(true),
        iconTemplate: <DeleteForeverOutlined />,
        dialogTemplate: <></>,
        disabled: editActionsDisabled
      }
    }),
    [
      shareAnchorEl,
      moveAnchorEl,
      copyAnchorEl,
      editActionsDisabled,
      shareDisabled,
      deleteDisabled,
      selectedFilesIds
    ]
  );

  return (
    <>
      {!!actionList.length && (
        <ButtonGroup disabled={allActionsDisabled} sx={sx}>
          {actionList.map((actionName, index) => (
            <ActionButton
              key={index}
              id={ActionsFile[actionName]}
              variant="text"
              onClick={actionsData[actionName].event}
              startIcon={actionsData[actionName].iconTemplate}
              disabled={actionsData[actionName].disabled}
              disableTouchRipple
            >
              {t(`bulkAction.${getWordWithLowerCase(actionName)}.label`)}
              {actionsData[actionName].dialogTemplate}
            </ActionButton>
          ))}
        </ButtonGroup>
      )}
      <ConfirmationModal
        body={
          !deleteDisabled
            ? t('confirmation-modal.warning-message', {
                fileLength: selectedFilesIds.length
              })
            : t('confirmation-modal.attention-message')
        }
        isHiddenConfirm={deleteDisabled}
        successMessage={t('confirmation-modal.agree-delete')}
        handleClose={() => setShowConfirmationModal(false)}
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={handleFilesDelete}
        isOpen={isShowConfirmationModal}
      />
      {/* TODO: Change ContentCopy/DeleteForeverOutlined icons if needed, dialogs for Download/Share/Delete */}
      <InformationModal
        isOpen={isOpenInformationModal}
        title={t('downloadInformationModalDesktop.title')}
        content={t('downloadInformationModalDesktop.content')}
        confirmText={t('downloadInformationModalDesktop.confirmText')}
        handleClose={() => setIsOpenInformationModal(false)}
      />
    </>
  );
};
