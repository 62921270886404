// @ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Auth from '@aws-amplify/auth';

import {
  getFilesByURL,
  transformToFiles,
  updateSharedFilesWithAdditionalInfo,
  updateWithAdditionalInfo
} from '@features/files/utils';
import { ENVIRONMENT, PLATFORM } from '@aws';
import {
  FetchFilesResponse,
  File,
  UploadFilesRequest,
  ROOT_DIRECTORY,
  UploadFilesResponse,
  UploadFileCompleteRequest,
  UploadFileCompleteRequestItem,
  FilesOperationRequest,
  ShareFilesRequestItem,
  ShareFilesRequest,
  FileDetailsResponse,
  DownloadIdResponse,
  DownloadUrlResponse,
  DeleteUserDataResponse,
  ExportUsersParams,
  ExportUsersResponse,
  FilesRequest,
  CreateDirectoryResponse,
  ModifyFileResult,
  CopyUrlResponse,
  FileCommentsResponse,
  CommentEmoticons
} from '@features/files/types';
import { EntityId } from '@reduxjs/toolkit';
import { AnyType } from 'src/globalTypes';
import update from 'immutability-helper';
import { compareDates } from '@utils/timeUtils';
import { UserProfileDataResponse } from './users/types';

const FILE = 'File' as const;
const FILE_DETAILS = 'File_Details' as const;
const FILE_COMMENTS = 'File_Comments' as const;
const OWNED_LIST = 'OWNED_LIST';
const SHARED_LIST = 'SHARED_LIST';
const RECEIVED_LIST = 'RECEIVED_LIST';
const TRASH_LIST = 'TRASH_LIST';

const getOwnedFilesUrl = (directoryId: string) =>
  `/files${directoryId === ROOT_DIRECTORY ? '/my' : `/${directoryId}`}`;

const getTrashFilesUrl = () => '/files/trashbin';
const getReceivedFilesUrl = (directoryId: string) =>
  `/files/received${directoryId === ROOT_DIRECTORY ? '' : `/${directoryId}`}`;
const getSharedFilesUrl = (directoryId: string) =>
  `/files/shared${directoryId === ROOT_DIRECTORY ? '' : `/${directoryId}`}`;

const getFilesCommentsUrl = (fileId: string) => `/files/${fileId}/comments`;

export const getTransformedPayloadToExtensivePayload = (
  ids: string[],
  parameters?: FilesOperationRequest<'EXTENSIVE_PAYLOAD'>['payload']['parameters']
): FilesOperationRequest<'EXTENSIVE_PAYLOAD'>['payload'] => {
  let data = {
    files: ids.map((id) => ({
      file_id: id
    })),
    parameters: {}
  };
  if (parameters) {
    data = update(data, {
      $merge: {
        parameters
      }
    });
  }

  return data;
};

const providesList = <R extends { file_id: string }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  listId?: string
) => {
  return resultsWithIds?.length
    ? [
        { type: tagType, id: listId },
        ...resultsWithIds.map(({ file_id }) => ({ type: tagType, id: file_id }))
      ]
    : [{ type: tagType, id: listId }];
};

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: PLATFORM[ENVIRONMENT].file_base_url,
    prepareHeaders: async (headers) => {
      const session = await Auth.currentSession();

      if (session) {
        headers.set('Authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
      }

      return headers;
    }
  }),
  tagTypes: [FILE, FILE_DETAILS, FILE_COMMENTS],
  endpoints: (builder) => ({
    getOwnedFiles: builder.query<File[], string>({
      queryFn: async (directoryId: string, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let ownedFiles: File[] = [];

        const ownedFilesResult = await fetchWithBaseQuery(getOwnedFilesUrl(directoryId));
        const ownedFilesResultData = ownedFilesResult.data as FetchFilesResponse;
        const ownedFilesUrl = ownedFilesResultData?.url;

        if (ownedFilesUrl) {
          ownedFiles = await getFilesByURL(ownedFilesUrl);
        } else {
          ownedFiles = ownedFilesResultData?.files || [];
        }

        return { data: ownedFiles.map(updateWithAdditionalInfo) };
      },
      providesTags: (files: File[] | undefined) => providesList(files, FILE, OWNED_LIST)
    }),
    getReceivedFiles: builder.query<File[], string>({
      queryFn: async (directoryId: string, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let receivedFiles: File[] = [];

        const receivedFilesResult = await fetchWithBaseQuery(getReceivedFilesUrl(directoryId));
        const receivedFilesResultData = receivedFilesResult.data as FetchFilesResponse;
        const receivedFilesUrl = receivedFilesResultData?.url;

        if (receivedFilesUrl) {
          receivedFiles = await getFilesByURL(receivedFilesUrl);
        } else {
          receivedFiles = receivedFilesResultData?.files || [];
        }

        return { data: receivedFiles.map(updateWithAdditionalInfo) };
      },
      providesTags: (files: File[] | undefined) => providesList(files, FILE, RECEIVED_LIST)
    }),
    getSharedFiles: builder.query<File[], string>({
      queryFn: async (directoryId: string, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let sharedFiles: File[] = [];

        const sharedFilesResult = await fetchWithBaseQuery(getSharedFilesUrl(directoryId));
        const sharedFilesResultData = sharedFilesResult.data as FetchFilesResponse;
        const sharedFilesUrl = sharedFilesResultData?.url;

        if (sharedFilesUrl) {
          sharedFiles = await getFilesByURL(sharedFilesUrl);
        } else {
          sharedFiles = sharedFilesResultData?.files || [];
        }

        return { data: sharedFiles.map(updateSharedFilesWithAdditionalInfo) };
      },
      providesTags: (files: File[] | undefined) => providesList(files, FILE, SHARED_LIST)
    }),
    getAllFiles: builder.query<File[], string>({
      queryFn: async (directoryId: string, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let ownedFiles: File[] = [];
        let receivedFiles: File[] = [];
        let sharedFiles: File[] = [];

        const ownedFilesResult = await fetchWithBaseQuery(getOwnedFilesUrl(directoryId));
        const receivedFilesResult = await fetchWithBaseQuery(getReceivedFilesUrl(directoryId));
        const sharedFilesResult = await fetchWithBaseQuery(getSharedFilesUrl(directoryId));

        const ownedFilesResultData = ownedFilesResult.data as FetchFilesResponse;
        const receivedFilesResultData = receivedFilesResult.data as FetchFilesResponse;
        const sharedFilesResultData = sharedFilesResult.data as FetchFilesResponse;

        const ownedFilesUrl = ownedFilesResultData?.url;
        const receivedFilesUrl = receivedFilesResultData?.url;
        const sharedFilesUrl = sharedFilesResultData?.url;

        if (ownedFilesUrl) {
          ownedFiles = await getFilesByURL(ownedFilesUrl);
        } else {
          ownedFiles = ownedFilesResultData?.files || [];
        }

        if (receivedFilesUrl) {
          receivedFiles = await getFilesByURL(receivedFilesUrl);
        } else {
          receivedFiles = receivedFilesResultData?.files || [];
        }

        if (sharedFilesUrl) {
          sharedFiles = await getFilesByURL(sharedFilesUrl);
        } else {
          sharedFiles = sharedFilesResultData?.files || [];
        }

        const seen = new Set();

        return {
          data: sharedFiles
            .map((file) => ({ ...file, isShared: true, isReceived: false }))
            .concat(receivedFiles.map((file) => ({ ...file, isShared: false, isReceived: true })))
            .concat(ownedFiles.map((file) => ({ ...file, isShared: false, isReceived: false })))
            .map(updateWithAdditionalInfo)
            .filter((el) => {
              const duplicate = seen.has(el.file_id);
              seen.add(el.file_id);
              return !duplicate;
            })
          // TODO add error response
        };
      },
      providesTags: (files: File[] | undefined) => {
        let tags = [
          { type: FILE, id: OWNED_LIST },
          { type: FILE, id: RECEIVED_LIST }
        ];

        if (files?.length) {
          tags = tags.concat(files.map(({ file_id }) => ({ type: FILE, id: file_id })));
        }

        return tags;
      }
    }),
    getFileDetails: builder.query<FileDetailsResponse, string>({
      query: (fileId: string) => `/files/${fileId}/details`,
      providesTags: (details: FileDetailsResponse | undefined) => [
        { type: FILE_DETAILS, id: details?.file_id }
      ]
    }),
    getFileComments: builder.query<FileCommentsResponse[], string>({
      query: (fileId: string) => ({
        url: `/files/${fileId}/comments`,
        method: 'GET'
      }),
      transformResponse: (response: any) =>
        response.sort((a, b) => compareDates(a.comment_date, b.comment_date)),
      providesTags: (result, error, file_id) => [{ type: FILE_COMMENTS, id: file_id }]
    }),
    addFileComment: builder.mutation<FileCommentsResponse, any>({
      query: (payload: any) => ({
        url: `/files/${payload.file_id}/comments`,
        method: 'POST',
        body: { comment_text: payload.comment_text }
      }),
      invalidatesTags: (result, error, data) => [{ type: FILE_COMMENTS, id: data.file_id }]
    }),
    setFileComment: builder.mutation<FileCommentsResponse, any>({
      query: (payload: any) => ({
        url: `/files/${payload.file_id}/comments/${payload.comment_id}/text`,
        method: 'POST',
        body: { comment_text: payload.comment_text }
      }),
      invalidatesTags: (result, error, data) => [{ type: FILE_COMMENTS, id: data.file_id }]
    }),
    deleteFileComment: builder.mutation<FileDetailsResponse, any>({
      query: ({ file_id, comment_id }: any) => ({
        url: `/files/${file_id}/comments/${comment_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, data) => [{ type: FILE_COMMENTS, id: data.file_id }]
    }),
    putCommentEmoji: builder.mutation<CommentEmoticons[], any>({
      query: (payload: any) => ({
        url: `/files/${payload.file_id}/comments/${payload.comment_id}/emoticons`,
        method: 'POST',
        body: payload.data
      }),
      invalidatesTags: (result, error, data) => [{ type: FILE_COMMENTS, id: data.file_id }]
    }),
    shareFiles2: builder.mutation<AnyType, ShareFilesRequestItem[]>({
      query: (payload: ShareFilesRequestItem[]) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'SHARE',
          payload
        } as ShareFilesRequest<'SINGLE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE_DETAILS }]
    }),
    createDirectories: builder.mutation<CreateDirectoryResponse, UploadFilesRequest>({
      query: (directories: UploadFilesRequest) => ({
        url: '/upload',
        method: 'PUT',
        body: {
          payload: directories
        } as FilesRequest<UploadFilesRequest, 'SINGLE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),
    createDirectoryWithoutTag: builder.mutation<CreateDirectoryResponse, UploadFilesRequest>({
      query: (directories: UploadFilesRequest) => ({
        url: '/upload',
        method: 'PUT',
        body: {
          payload: directories
        } as FilesRequest<UploadFilesRequest, 'SINGLE_PAYLOAD'>
      })
    }),
    postUploadStatus: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: '/uploads/status',
        method: 'POST',
        body: payload
      })
    }),

    uploadFiles: builder.mutation<UploadFilesResponse, UploadFilesRequest>({
      query: (files: UploadFilesRequest) => ({
        url: '/upload',
        method: 'PUT',
        body: {
          payload: files
        } as FilesRequest<UploadFilesRequest, 'SINGLE_PAYLOAD'>
      })
    }),
    completeFilesUpload: builder.mutation<CreateDirectoryResponse, UploadFileCompleteRequestItem[]>(
      {
        query: (files: UploadFileCompleteRequestItem[]) => ({
          url: '/upload/complete',
          method: 'POST',
          body: {
            payload: files
          } as UploadFileCompleteRequest<'SINGLE_PAYLOAD'>
        }),
        invalidatesTags: [{ type: FILE }]
      }
    ),
    getFilesDownloadId: builder.mutation<string, string[]>({
      query: (ids: string[]) => ({
        url: '/files/download',
        method: 'POST',
        body: ids
      }),
      transformResponse: (response: DownloadIdResponse) => response.download_id
    }),
    getFilesDownloadLink: builder.mutation<DownloadUrlResponse, string | undefined>({
      query: (downloadId: string) => ({
        url: `/files/download/${downloadId}`,
        method: 'GET'
      })
    }),
    deleteFiles: builder.mutation<void, string[]>({
      query: (ids: string[]) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'SOFT_DELETE',
          payload: getTransformedPayloadToExtensivePayload(ids)
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),
    copyFiles: builder.mutation<
      { payload: { result: string }[] },
      { ids: string[]; destinationId: string }
    >({
      query: ({ ids, destinationId }) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'COPY',
          payload: getTransformedPayloadToExtensivePayload(ids, { dir_id: destinationId })
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      })
    }),
    getFilesCopyLink: builder.mutation<CopyUrlResponse, string | undefined>({
      query: (operation_id: string) => ({
        url: `/files/status/${operation_id}`,
        method: 'GET'
      })
    }),
    moveFiles: builder.mutation<void, { ids: string[]; destinationId: string }>({
      query: ({ ids, destinationId }) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'MOVE',
          payload: getTransformedPayloadToExtensivePayload(ids, { dir_id: destinationId })
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),

    moveFilesWithoutTag: builder.mutation<void, { ids: string[]; destinationId: string }>({
      query: ({ ids, destinationId }) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'MOVE',
          payload: getTransformedPayloadToExtensivePayload(ids, { dir_id: destinationId })
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      })
    }),
    shareFiles: builder.mutation<AnyType, ShareFilesRequestItem[]>({
      query: (payload: ShareFilesRequestItem[]) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'SHARE',
          payload
        } as ShareFilesRequest<'SINGLE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),
    modifyFiles: builder.mutation<any>({
      query: (files) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'MODIFY',
          payload: files
        }
      }),
      invalidatesTags: (result, error, files) => [
        { type: FILE },
        ...files.files.map((file) => ({ type: FILE_DETAILS, id: file.file_id }))
      ]
    }),
    refetchFiles: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: [{ type: FILE }]
    }),
    getShairedFilesByUserId: builder.query({
      query: (userId: string) => `/mynetwork/${userId}`,
      transformResponse: transformToFiles
    }),
    deleteUserWithShairedFiles: builder.mutation<DeleteUserDataResponse, EntityId[]>({
      query: (usersIds: EntityId[]) => ({
        url: '/mynetwork',
        method: 'DELETE',
        body: {
          payload: {
            users: usersIds
          }
        }
      })
    }),
    getTrashFiles: builder.query<File[], string>({
      queryFn: async (directoryId: string, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let trashFiles: File[] = [];

        const trashFilesResult = await fetchWithBaseQuery(getTrashFilesUrl());

        const trashFilesResultData = trashFilesResult.data as FetchFilesResponse;

        const trashFilesUrl = trashFilesResultData?.url;

        if (trashFilesUrl) {
          trashFiles = await getFilesByURL(trashFilesUrl);
        } else {
          trashFiles = trashFilesResultData?.files || [];
        }

        return {
          data: trashFiles.map(updateWithAdditionalInfo)
          // TODO add error response
        };
      },
      providesTags: (files: File[] | undefined) => providesList(files, FILE, TRASH_LIST)
    }),
    restoreFiles: builder.mutation<void, string[]>({
      query: (ids: string[]) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'RESTORE',
          payload: getTransformedPayloadToExtensivePayload(ids)
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),
    deletePermanently: builder.mutation<void, string[]>({
      query: (ids: string[]) => ({
        url: '/files/operations',
        method: 'POST',
        body: {
          type: 'HARD_DELETE',
          payload: getTransformedPayloadToExtensivePayload(ids)
        } as FilesOperationRequest<'EXTENSIVE_PAYLOAD'>
      }),
      invalidatesTags: [{ type: FILE }]
    }),
    exportUsers: builder.mutation<string, ExportUsersParams>({
      query: ({ usersIds, userRole }: ExportUsersParams) => ({
        url: `users/export/${userRole}`,
        method: 'POST',
        body: {
          payload: {
            users: usersIds
          }
        }
      }),
      transformResponse: (response: ExportUsersResponse) => response.download_id
    }),
    getCustomerAccountInfo: builder.query<
      { profile: NonNullable<UserProfileDataResponse['user']> },
      void
    >({
      query: () => `/user/profile`
    }),
    getUserProfileById: builder.query<UserProfileData, string>({
      query: (userId) => `/users/${userId}/profile`
    })
  })
});

export const {
  usePostUploadStatusMutation,
  useGetAllFilesQuery,
  useGetReceivedFilesQuery,
  useGetOwnedFilesQuery,
  useGetSharedFilesQuery,
  useLazyGetAllFilesQuery,
  useLazyGetOwnedFilesQuery,
  useLazyGetReceivedFilesQuery,
  useLazyGetSharedFilesQuery,
  useCreateDirectoriesMutation,
  useGetFilesDownloadIdMutation,
  useGetFilesDownloadLinkMutation,
  useUploadFilesMutation,
  useCompleteFilesUploadMutation,
  useDeleteFilesMutation,
  useCopyFilesMutation,
  useMoveFilesMutation,
  useShareFilesMutation,
  useModifyFilesMutation,
  useGetShairedFilesByUserIdQuery,
  useDeleteUserWithShairedFilesMutation,
  useExportUsersMutation,
  useCreateDirectoryWithoutTagMutation,
  useMoveFilesWithoutTagMutation,
  useGetCustomerAccountInfoQuery,
  useGetUserProfileByIdQuery,
  useGetFilesCopyLinkMutation,
  useGetFileDetailsQuery,
  useGetFileCommentsQuery,
  useAddFileCommentMutation,
  useSetFileCommentMutation,
  usePutCommentEmojiMutation,
  useDeleteFileCommentMutation,
  useShareFiles2Mutation,
  useGetTrashFilesQuery,
  useRestoreFilesMutation,
  useDeletePermanentlyMutation
} = apiSlice;
